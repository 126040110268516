import React from 'react'
import { graphql } from 'gatsby'
import Banner from '../components/Banner'
import AboutSection from '../components/AboutSection'
import WorkSection from '../components/WorkSection'
import TeamSection from '../components/TeamSection'
import ContactSection from '../components/ContactSection'

import Layout from './../components/Layout'

const IndexPage = () => (
  <Layout>
    <Banner />
    <div className="relative z-10 bg-white pb-28">
      <AboutSection />
      <WorkSection />
      <TeamSection />
      <ContactSection />
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
