import React, { useState, useRef, useLayoutEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import scrollTo from 'gatsby-plugin-smoothscroll'
import BannerImage from '../img/brainmerge_animation.inline.svg'
import { Trans } from 'gatsby-plugin-react-i18next'

const container = {
  show: {
    transition: {
      staggerChildren: 0.4,
    },
  },
}

const title = {
  hidden: { opacity: 0, y: 100 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, 0.2, 1],
      duration: 1.4,
    },
  },
}

const subtitle = {
  hidden: { opacity: 0, y: 30 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, 0.2, 1],
      duration: 1.6,
    },
  },
}

const offset = 300
const imageOffset = offset * 0.7

const Banner = () => {
  const [titleTop, setTitleTop] = useState(0)
  const titleRef = useRef(null)

  const { scrollY } = useViewportScroll()

  const final = titleTop + offset

  const opacity = useTransform(scrollY, [0, final], [1, 0])
  const yTitle = useTransform(scrollY, [0, final], [0, offset])
  const yImage = useTransform(scrollY, [0, final], [0, imageOffset])

  useLayoutEffect(() => {
    const titleEl = titleRef.current

    const onResize = () => {
      if (titleEl) {
        setTitleTop(
          titleEl.getBoundingClientRect().top + window.scrollY ||
            window.pageYOffset
        )
      }
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [titleRef])

  return (
    <section
      id="home"
      className="relative grid h-screen grid-cols-1 grid-rows-1"
    >
      <motion.div
        style={{ y: yImage }}
        className="col-start-1 row-start-1 h-full"
      >
        <BannerImage className="h-full w-full" />
      </motion.div>
      <div className="col-start-1 row-start-1 grid place-items-center">
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="relative mx-auto w-full pt-64 text-center sm:mx-0 sm:ml-24 sm:text-left"
          style={{ y: yTitle, opacity }}
          ref={titleRef}
        >
          <motion.div variants={title}>
            <h1 className="font-primary text-5xl font-extrabold uppercase text-white sm:text-7xl">
              Merge <span className="text-blue">Minds</span>
            </h1>
          </motion.div>
          <motion.div
            variants={subtitle}
            className="mt-2 flex flex-col text-2xl tracking-tight text-white text-opacity-90 sm:mt-4 sm:text-4xl"
          >
            <Trans
              i18nKey="site.catchPhrase-html"
              components={[<span />, <span className="-mt-2 sm:-mt-1" />]}
            >
              <span>Web apps</span>
              <span className="-mt-2 sm:-mt-1">for innovating groups.</span>
            </Trans>
          </motion.div>
        </motion.div>
      </div>
      <button
        aria-label="Scroll down"
        onPointerDown={() => {
          scrollTo('#about')
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-24 left-0 right-0 mx-auto h-8 w-8 animate-bounce text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
    </section>
  )
}
export default Banner
