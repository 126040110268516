import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import cx from '../utils/cx'
import Twitter from '.././img/social/twitter.inline.svg'
import LinkedIn from '.././img/social/linkedin.inline.svg'
import Github from '.././img/social/git.inline.svg'

const SocialIcon = ({
  iconInfo: {
    url,
    type: { identifier },
  },
}) => {
  const [hover, setHover] = useState(false)
  let logoBg = useRef({
    bgColor: 'black',
  })

  const logos = {
    twitter: {
      color: '#1DA1F2',
    },
    linkedin: {
      color: '#0077B5',
    },
    github: {
      color: '#555',
    },
  }

  useEffect(() => {
    logoBg.current.bgColor = logos[identifier].color
  }, [])

  const getIcon = (identifier, hovered = false) => {
    const logoCss = cx(
      'w-4 fill-current',
      hovered ? 'text-white' : 'text-gray-300'
    )

    if (identifier === 'twitter') {
      return <Twitter className={logoCss} />
    }

    if (identifier === 'linkedin') {
      logoBg.current.bgColor = logos[identifier].color
      return <LinkedIn className={logoCss} />
    }

    if (identifier === 'github') {
      return <Github className={logoCss} />
    }
  }

  return (
    <a
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      target="_blank"
      rel="noopener"
      href={url}
      aria-label={`${identifier}`}
      className={`social-icon si-rounded si-small si-${identifier} relative mt-5 inline-block h-8 w-8 overflow-hidden rounded-full transition duration-300 ease-in-out`}
    >
      <div
        className={cx(
          'absolute flex h-full w-full transform items-center justify-center rounded-full transition duration-300 ease-in-out',
          !hover && 'translate-y-4'
        )}
        style={{
          backgroundColor: logoBg.current.bgColor,
        }}
      >
        {getIcon(identifier, true)}
      </div>
      <div
        className={cx(
          'flex h-full w-full transform items-center justify-center bg-white transition duration-300 ease-in-out',
          hover && '-translate-y-8'
        )}
      >
        {getIcon(identifier)}
      </div>
    </a>
  )
}

SocialIcon.propTypes = {
  iconInfo: PropTypes.object,
}

export default SocialIcon
