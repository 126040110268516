import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Container from './Container'
import Divider from './Divider'
import { LandingTitle, LandingSubtitle } from './Text'
import cx from '../utils/cx'

const capitalize = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export default function WorkSection() {
  const { t } = useTranslation()

  const { allProjectsJson } = useStaticQuery(
    graphql`
      {
        allProjectsJson {
          nodes {
            title
            type
            tags
            link
            customer
            customerLogo {
              childImageSharp {
                gatsbyImageData
              }
            }
            gallery {
              main {
                alt
                src {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const workData = allProjectsJson.nodes.filter((work) => work.type === 'work')
  const playData = allProjectsJson.nodes.filter((work) => work.type === 'play')

  return (
    <Container id="work" className="text-center">
      <LandingTitle>{t('work.title')}</LandingTitle>
      <LandingSubtitle>{t('work.subtitle')}</LandingSubtitle>
      <Divider />
      <div className="mt-12 grid gap-x-8 gap-y-8 sm:grid-flow-row sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-12">
        {workData.map((project, i) => (
          <Project key={`project-${i}`} project={project} />
        ))}
      </div>
      <LandingSubtitle className="pt-16">
        {t('work.subtitlePlay')}
      </LandingSubtitle>
      <Divider />
      <div className="mx-auto mt-12">
        <Project key={`project-0`} project={playData[0]} />
      </div>
    </Container>
  )
}

function Project({ project }) {
  const { t } = useTranslation()

  return (
    <Link
      className="group flex flex-col items-center text-center"
      to={`project${project.link}`}
    >
      <div className="relative">
        {project.customerLogo && (
          // wrapper: https://github.com/gatsbyjs/gatsby/issues/5727#issuecomment-1081479991
          <div className="absolute left-2 top-2 z-10 w-full text-left">
            <GatsbyImage
              image={getImage(project.customerLogo)}
              alt={project.customer}
              className="peer aspect-square h-12 md:h-12"
              aria-describedby="customer-logo"
            />
            <div
              role="tooltip"
              id={`customer-logo-${project.name}`}
              className={cx(
                'absolute left-20 top-1/2 z-20 -ml-4 -translate-y-1/2 rounded-md bg-white px-2 py-1 text-sm shadow-xl',
                'before:absolute before:-left-2 before:top-1/2 before:inline-block before:-translate-y-1/2 before:border-b-8 before:border-r-[10px] before:border-t-8 before:border-b-transparent before:border-r-white before:border-t-transparent',
                'invisible opacity-0 transition-opacity duration-300 ease-in-out',
                'peer-hover:visible peer-hover:opacity-100'
              )}
            >
              {project.customer}
            </div>
          </div>
        )}
        <GatsbyImage
          image={getImage(project.gallery.main.src)}
          alt={project.gallery.main.alt}
          placeholder="blurred"
          className="h-56 w-56 object-cover transition-transform duration-500 ease-in-out group-hover:scale-105"
        />
      </div>
      <div className="mt-4 font-primary font-semibold !leading-tight lg:text-lg">
        {t(project.title)}
      </div>
      <div className="mt-0.5 text-sm text-blue lg:text-base">
        {project.tags.map(capitalize).join(' / ')}
      </div>
    </Link>
  )
}
