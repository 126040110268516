import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SocialIcon from './SocialIcon'

const TeamMember = ({ profile, img }) => {
  const { t } = useTranslation()

  return (
    <div className="mx-auto max-w-xs pt-10 sm:flex sm:max-w-xl sm:items-start lg:w-1/2 lg:pl-4 xl:pl-10">
      <div className="mx-auto aspect-square w-48">
        <GatsbyImage
          image={img}
          alt={profile.altProfileImageText}
          className="h-full w-full rounded-full object-cover object-center contrast-75"
        />
      </div>
      <div className="flex-1 text-white sm:pl-6">
        <div className="mt-3">
          <div className="font-primary text-lg font-extrabold uppercase leading-snug text-gray-500 xl:text-xl">
            {profile.name}
          </div>
          <span className="mt-1 inline-block text-yellow-1">
            {profile.title}
          </span>
        </div>
        <p className="mt-2 text-sm">{t(profile.description)}</p>

        {profile.socialIcons.map((item, index) => (
          <SocialIcon iconInfo={item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default TeamMember
