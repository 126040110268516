import { Enum } from 'enumify'

/* Common constants
_______________________________________________________________________________________*/

class SocialIcons extends Enum {}
SocialIcons.initEnum({
  TWITTER: {
    get identifier() {
      return 'twitter'
    },
  },

  DELICIOUS: {
    get identifier() {
      return 'delicious'
    },
  },

  ANDROID: {
    get identifier() {
      return 'android'
    },
  },

  GPLUS: {
    get identifier() {
      return 'gplus'
    },
  },

  FACEBOOK: {
    get identifier() {
      return 'facebook'
    },
  },

  PINTEREST: {
    get identifier() {
      return 'pinterest'
    },
  },

  INSTAGRAM: {
    get identifier() {
      return 'instagram'
    },
  },

  LINKEDIN: {
    get identifier() {
      return 'linkedin'
    },
  },

  FLICKR: {
    get identifier() {
      return 'flickr'
    },
  },

  REDDIT: {
    get identifier() {
      return 'reddit'
    },
  },

  TUMBLR: {
    get identifier() {
      return 'tumblr'
    },
  },

  YOUTUBE: {
    get identifier() {
      return 'youtube'
    },
  },

  VIMEO: {
    get identifier() {
      return 'vimeo'
    },
  },

  GITHUB: {
    get identifier() {
      return 'github'
    },
  },
})
export { SocialIcons }
