import { SocialIcons } from './utils/constants'

import imgStefan from './img/team/stefan.jpg'
import imgSteve from './img/team/steve.jpg'
import imgAhmed from './img/team/ahmed.jpg'
import imgAlaaeddine from './img/team/alaaeddine.jpg'
import imgEve from './img/team/eve.jpg'
import imgJulian from './img/team/julian.jpg'
import imgChrysa from './img/team/chrysa.jpg'

/*
 * HINT: Changing face size - easiest to
 * - open image in gimp
 * - Create new image with larger canvas size
 * - copy old image, paste into new
 * - deselect all in new image
 * - export to jpg
 */

const team = {
  stefan: {
    name: 'Stefan Baumann',
    title: 'CEO',
    // translation
    description: 'team.desc.stefan',
    profileImage: imgStefan,
    altProfileImageText: 'Stefan Baumann',
    socialIcons: [
      {
        type: SocialIcons.LINKEDIN,
        url: 'https://ch.linkedin.com/in/stefan-baumann-el-ghatta-3386722',
      },
    ],
  },
  steve: {
    name: 'Steve Pieper',
    title: 'Architect',
    description: 'team.desc.steve',
    profileImage: imgSteve,
    altProfileImageText: 'Steve Pieper',
    socialIcons: [
      {
        type: SocialIcons.GITHUB,
        url: 'https://github.com/pieper',
      },
    ],
  },
  ahmed: {
    name: 'Ahmed Hosny',
    title: 'Data Visualization',
    description: 'team.desc.ahmed',
    profileImage: imgAhmed,
    altProfileImageText: 'Ahmed Hosny',
    socialIcons: [
      {
        type: SocialIcons.TWITTER,
        url: 'https://twitter.com/theahmedhosny',
      },
    ],
  },
  alaaeddine: {
    name: 'Alaa-Eddine Kaddouri',
    title: 'Back-end',
    description: 'team.desc.alaaeddine',
    profileImage: imgAlaaeddine,
    altProfileImageText: 'Alaa-Eddine Kaddouri',
    socialIcons: [
      {
        type: SocialIcons.GITHUB,
        url: 'https://github.com/alaa-eddine',
      },
    ],
  },
  eve: {
    name: 'Eve Freeman',
    title: 'Database',
    description: 'team.desc.eve',
    profileImage: imgEve,
    altProfileImageText: 'Eve Freeman',
    socialIcons: [
      {
        type: SocialIcons.GITHUB,
        url: 'https://github.com/freeeve',
      },
    ],
  },
  julian: {
    name: 'Julian Krispel',
    title: 'Front-end',
    description: 'team.desc.julian',
    profileImage: imgJulian,
    altProfileImageText: 'Julian Krispel',
    socialIcons: [
      {
        type: SocialIcons.GITHUB,
        url: 'https://github.com/juliankrispel',
      },
    ],
  },
  chrysa: {
    name: 'Chrysa Chouliara',
    title: 'Design, UI/UX',
    description: 'team.desc.chrysa',
    profileImage: imgChrysa,
    altProfileImageText: 'Chrysa Chouliara',
    socialIcons: [
      {
        type: SocialIcons.LINKEDIN,
        url: 'https://www.linkedin.com/in/kaascat/',
      },
    ],
  },
}

export default team
