import React from 'react'

import Container from './Container'
import Divider from './Divider'
import { LandingTitle } from './Text'
import analyze from '../img/analyze.svg'
import develop from '../img/develop.svg'
import execute from '../img/execute.svg'
import Minibrain from '../img/minibrain.inline.svg'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

export default function AboutSection() {
  const { t } = useTranslation()

  return (
    <Container id="about" className="">
      <LandingTitle>
        <Trans
          i18nKey="about.weDo-html"
          components={[<span className="text-blue" />]}
        >
          We <span className="text-blue">improve</span> group decisions.
        </Trans>
      </LandingTitle>
      <Divider />
      <div className="prose prose-lg mt-8 prose-li:pl-5">
        <p className="mx-auto max-w-prose">{t('about.weAre')}</p>
        <div className="mx-auto max-w-prose">
          <h3>{t('about.uncertaintyStrength')}</h3>
          <p>{t('about.uncertaintyStrengthText')}</p>
        </div>
        <div className="mx-auto max-w-prose">
          <h3>{t('about.wePartner')}</h3>
          <p>{t('about.wePartnerText')}</p>
          <ul className="not-prose max-w-2xl space-y-2 pl-0 text-left">
            <CheckItem>{t('about.wePartnerP1')}</CheckItem>
            <CheckItem>{t('about.wePartnerP2')}</CheckItem>
            <CheckItem>{t('about.wePartnerP3')}</CheckItem>
          </ul>
        </div>

        <div>
          <h3 className="text-center">{t('about.hCollab')}</h3>
          <div className="not-prose mx-auto mt-8 grid max-w-2xl items-stretch gap-8 md:grid-cols-3 lg:max-w-3xl lg:grid-rows-1 xl:max-w-4xl">
            <HowWeWorkItem
              icon={<img src={analyze} alt={t('about.hCollabAnalyze')} />}
              title={t('about.hCollabAnalyze')}
            >
              <li>{t('about.hCollabA1')}</li>
              <li>{t('about.hCollabA2')}</li>
              <li>{t('about.hCollabA3')}</li>
            </HowWeWorkItem>

            <HowWeWorkItem
              icon={<img src={develop} alt={t('about.hCollabDevelop')} />}
              title={t('about.hCollabDevelop')}
            >
              <li>{t('about.hCollabD1')}</li>
              <li>{t('about.hCollabD2')}</li>
              <li>{t('about.hCollabD3')}</li>
            </HowWeWorkItem>

            <HowWeWorkItem
              icon={<img src={execute} alt={t('about.hCollabExecute')} />}
              title={t('about.hCollabExecute')}
            >
              <li>{t('about.hCollabE1')}</li>
              <li>{t('about.hCollabE2')}</li>
              <li>{t('about.hCollabE3')}</li>
            </HowWeWorkItem>
          </div>
        </div>
      </div>
    </Container>
  )
}

function HowWeWorkItem({ icon, title, children }) {
  return (
    <div className="flex flex-col items-center">
      <div className="h-20 w-20 p-2">{icon}</div>
      <h4 className="text-xl tracking-tight text-gray-500">{title}</h4>
      <ul className="mt-8 flex-1 list-disc space-y-2 border-blue border-opacity-50 bg-blue bg-blue-high p-5 pl-8 text-base text-white shadow-lg">
        {children}
      </ul>
    </div>
  )
}

function CheckItem({ children }) {
  return (
    <li className="flex items-center">
      <Minibrain className="h-3 w-3 flex-shrink-0 text-blue-high" />
      <span className="pl-2 leading-tight">{children}</span>
    </li>
  )
}
