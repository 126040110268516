import React from 'react'

import cx from '../utils/cx'

export default function Container({ children, className, ...rest }) {
  return (
    <section
      className={cx(
        'mx-auto max-w-xl px-8 pt-20 md:max-w-4xl md:pt-28 xl:max-w-6xl',
        className
      )}
      {...rest}
    >
      {children}
    </section>
  )
}
