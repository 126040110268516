import React from 'react'

import useEmail from '../hooks/useEmail'
import cx from '../utils/cx'
import EmailLink from './EmailLink'
import { LandingSubtitle, LandingTitle } from './Text'
import Divider from './Divider'
import Container from './Container'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Label = ({ htmlFor, children }) => {
  return (
    <label
      className="font-primary text-xs font-bold uppercase tracking-widest text-gray-300"
      htmlFor={htmlFor}
    >
      {children}
    </label>
  )
}

const inputClasses = cx(
  'block w-full mt-2 font-secondary text-gray-300 border border-gray-200 border-opacity-40 rounded-sm',
  'focus:ring-0 focus:outline-none focus:border-gray-200 focus:border-opacity-70 transition duration-300 ease-in-out'
)

const Input = ({ className, ...props }) => {
  return <input className={cx(inputClasses, className)} {...props} />
}

const Textarea = ({ className, ...props }) => {
  return (
    <textarea
      className={cx(inputClasses, 'w-full max-w-3xl', className)}
      {...props}
    />
  )
}

export default function ContactSection() {
  const { t } = useTranslation()

  const { email, emailParts, active } = useEmail()

  return (
    <Container id="contact">
      <div>
        <LandingTitle>{t('contact.title')}</LandingTitle>
      </div>

      <Divider />

      <div className="mt-12">
        <div>
          <div className="text-xl font-bold md:text-2xl">
            {t('contact.sendMsg')}
          </div>
          {active && (
            <div>
              <small className="text-xs text-gray-300">
                {t('contact.orEmail')}{' '}
                <EmailLink className="text-blue" email={email} />.
              </small>
            </div>
          )}
        </div>
        <div className="mt-10 font-primary ">
          <form
            id="template-contactform"
            name="template-contactform"
            // formspree still has the old
            action={`https://formspree.io/f/info@${emailParts[1]}`}
            method="post"
          >
            <div>
              <Label htmlFor="template-contactform-email">
                Email <small className="text-sm">*</small>
              </Label>
              <Input
                type="email"
                id="template-contactform-email"
                name="template-contactform-email"
                defaultValue=""
                className="md:max-w-sm"
              />
            </div>

            <div className="mt-6">
              <Label htmlFor="template-contactform-message">
                {t('contact.message')} <small className="text-sm">*</small>
              </Label>
              <Textarea
                id="template-contactform-message"
                name="template-contactform-message"
                rows={6}
                cols={30}
                defaultValue={''}
              />
            </div>
            <div>
              <button
                className={cx(
                  'transition-colors ease-out',
                  'mt-6 rounded bg-blue-high px-3 py-2 font-bold text-white hover:bg-gray-400'
                )}
                disabled={!active}
                type="submit"
                id="template-contactform-submit"
                name="template-contactform-submit"
                value="submit"
              >
                {t('contact.send')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  )
}
